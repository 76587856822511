import React from 'react'

const HeroComponent = () => {
  return (
    <div className="relative">
      <div className="absolute flex flex-col justify-center items-center h-full w-full mt-8 md:mt-12">
        <h1
          className="font-bold text-2xl md:text-5xl text-white uppercase"
          style={{
            textShadow: '0.2rem 0.2rem 0.1rem gray',
          }}
        >
          Gachadays
        </h1>
      </div>
      <img
        className="w-screen object-cover inset-0"
        src="https://gachadays.com/hero.png"
        alt=""
      />
    </div>
  )
}

export default HeroComponent
