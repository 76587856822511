import React from 'react'

const Footer = () => {
  const SUPPORT_EMAIL = process.env.GATSBY_SUPPORT_EMAIL

  return (
    <footer className="mx-auto bg-black text-white w-screen bottom-0">
      <div className="flex flex-wrap p-20">
        <div>
          <h3 className="font-bold text-white">Do you have any questions?</h3>
          <p className="py-2">{SUPPORT_EMAIL}</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
