import React from 'react'
import Nav from '../components/NavComponent/NavComponent'
import Footer from '../components/FooterComponent/FooterComponent'
import { Helmet } from 'react-helmet'
import HeroComponent from '../components/HeroComponent/HeroComponent'
import { Location } from '@reach/router'

const MainLayout = ({ children }) => {
  return (
    <div className="relative">
      <Helmet>
        <meta charSet="utf-8" />
        <title>gachadays</title>
        <link rel="canonical" href="https://gachadays.com/" />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
      </Helmet>
      <Nav />
      <Location>
        {({ location }) => {
          if (location.pathname === '/') return <HeroComponent />
        }}
      </Location>
      <main className="max-w-64 py-12 min-h-screen">{children}</main>
      <Footer />
    </div>
  )
}

export default MainLayout
